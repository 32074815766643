if ('define' in window) {
define("discourse/theme-23/discourse/initializers/avatar", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const settings = require("discourse/lib/theme-settings-store").getObjectForTheme(23);
  const themePrefix = key => `theme_translations.23.${key}`;
  var _default = _exports.default = {
    name: "discourse-avatar-component",
    initialize() {
      (0, _pluginApi.withPluginApi)("1.34.0", api => {
        api.changeWidgetSetting("post-avatar", "size", settings.topic_avatars_size);
        api.registerValueTransformer("header-notifications-avatar-size", () => settings.header_avatars_size);
      });
    }
  };
});
}
