(function() {
  /*
    <td
      class="posters topic-list-data theme-avatar-{{theme-setting
          'latest_avatar_size'
        }}"
    >
      {{#each posters as |poster|}}
        {{#if poster.moreCount}}
          <a class="posters-more-count">{{poster.moreCount}}</a>
        {{else}}
          <a
            href="{{poster.user.path}}"
            data-user-card="{{poster.user.username}}"
            class="{{poster.extraClasses}}"
          >
            {{avatar
              poster
              avatarTemplatePath="user.avatar_template"
              usernamePath="user.username"
              namePath="user.name"
              imageSize=(theme-setting "latest_avatar_size")
            }}
          </a>
        {{/if}}
      {{/each}}
    </td>

  */
  const addRawTemplate = requirejs('discourse-common/lib/raw-templates').addRawTemplate;
  const template = requirejs('discourse-common/lib/raw-handlebars').template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),"poster.moreCount",{"name":"if","hash":{},"hashTypes":{},"hashContexts":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":7,"column":4},"end":{"line":23,"column":11}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <a class=\"posters-more-count\">"
    + container.escapeExpression(lookupProperty(helpers,"get").call(depth0 != null ? depth0 : (container.nullContext || {}),"poster.moreCount",{"name":"get","hash":{},"hashTypes":{},"hashContexts":{},"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":8,"column":36},"end":{"line":8,"column":56}}}))
    + "</a>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <a\n        href=\""
    + alias2(lookupProperty(helpers,"get").call(alias1,"poster.user.path",{"name":"get","hash":{},"hashTypes":{},"hashContexts":{},"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":11,"column":14},"end":{"line":11,"column":34}}}))
    + "\"\n        data-user-card=\""
    + alias2(lookupProperty(helpers,"get").call(alias1,"poster.user.username",{"name":"get","hash":{},"hashTypes":{},"hashContexts":{},"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":12,"column":24},"end":{"line":12,"column":48}}}))
    + "\"\n        class=\""
    + alias2(lookupProperty(helpers,"get").call(alias1,"poster.extraClasses",{"name":"get","hash":{},"hashTypes":{},"hashContexts":{},"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":13,"column":15},"end":{"line":13,"column":38}}}))
    + "\"\n      >\n        "
    + alias2((lookupProperty(helpers,"avatar")||(depth0 && lookupProperty(depth0,"avatar"))||alias3).call(alias1,"poster",{"name":"avatar","hash":{"imageSize":(lookupProperty(helpers,"theme-setting")||(depth0 && lookupProperty(depth0,"theme-setting"))||alias3).call(alias1,23,"latest_avatar_size",{"name":"theme-setting","hash":{},"hashTypes":{},"hashContexts":{},"types":["NumberLiteral","StringLiteral"],"contexts":[depth0,depth0],"data":data,"loc":{"start":{"line":20,"column":20},"end":{"line":20,"column":56}}}),"namePath":"user.name","usernamePath":"user.username","avatarTemplatePath":"user.avatar_template"},"hashTypes":{"imageSize":"SubExpression","namePath":"StringLiteral","usernamePath":"StringLiteral","avatarTemplatePath":"StringLiteral"},"hashContexts":{"imageSize":depth0,"namePath":depth0,"usernamePath":depth0,"avatarTemplatePath":depth0},"types":["PathExpression"],"contexts":[depth0],"data":data,"loc":{"start":{"line":15,"column":8},"end":{"line":21,"column":10}}}))
    + "\n      </a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td\n  class=\"posters topic-list-data theme-avatar-"
    + container.escapeExpression((lookupProperty(helpers,"theme-setting")||(depth0 && lookupProperty(depth0,"theme-setting"))||container.hooks.helperMissing).call(alias1,23,"latest_avatar_size",{"name":"theme-setting","hash":{},"hashTypes":{},"hashContexts":{},"types":["NumberLiteral","StringLiteral"],"contexts":[depth0,depth0],"data":data,"loc":{"start":{"line":2,"column":46},"end":{"line":4,"column":6}}}))
    + "\"\n>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,"poster","in","posters",{"name":"each","hash":{},"hashTypes":{},"hashContexts":{},"fn":container.program(1, data, 0),"inverse":container.noop,"types":["PathExpression","CommentStatement","PathExpression"],"contexts":[depth0,depth0,depth0],"data":data,"loc":{"start":{"line":6,"column":2},"end":{"line":24,"column":11}}})) != null ? stack1 : "")
    + "</td>\n";
},"useData":true});
  addRawTemplate("list/posters-column", template);
})();
